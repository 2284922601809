import * as network from '../../network';
import * as storage from '../../storage';

// eslint-disable-next-line import/prefer-default-export
export const takeControl = async ({ channelSid, status }) => {
  const token = storage.get('TOKEN');
  const apiUrl = storage.get('SVC_GCP');
  return network.request(network.makeUrl(apiUrl, 'auth/admin/channels/status'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      channel_id: channelSid,
      status,
    }),
  });
};
