import React from 'react';
import qs from 'qs';
import { router, routerRedux, useLocation } from 'dva';
import * as storage from '../storage';
import Home from '../pages/Home';

const { ConnectedRouter } = routerRedux;
const { Switch, Route } = router;

const AppSwitch = () => {
  const location = useLocation();
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { apiUrl, token } = params;
  storage.set('SVC_GCP', apiUrl);
  storage.set('TOKEN', token);
  return (
    <Switch>
      <Route path="/">
        <Home />
      </Route>
    </Switch>
  );
};

const AppRouter = ({ history }) => {
  return (
    <ConnectedRouter history={history}>
      <AppSwitch />
    </ConnectedRouter>
  );
};

export default AppRouter;
