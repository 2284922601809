/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { Spin, Icon } from 'antd';
import { ChannelList } from '@negobot/nego-chat-react';
import useStyles from './styles';

const SiderContent = ({
  getMoreChannels,
  channels,
  channelsPaginator,
  getSubscribedChannels,
  getChannelBySid,
  initial,
}) => {
  const classes = useStyles();
  const [selectedChannel, setSelectedChannel] = useState(undefined);

  useEffect(() => {
    const initChannelList = async () => {
      await getSubscribedChannels();
    };
    initChannelList();
  }, [getSubscribedChannels]);

  useEffect(() => {
    const initChannel = async () => {
      if (selectedChannel) return;
      const { defaultChannel } = initial || {};
      let defaultChannelId = defaultChannel && defaultChannel.channelId;
      if (channels && channels.length > 0 && !defaultChannel) {
        defaultChannelId = channels[0].sid;
      }
      if (defaultChannelId) {
        setSelectedChannel(defaultChannelId);
        await getChannelBySid(defaultChannelId);
      }
    };
    initChannel();
  }, [initial, channels]);

  const onSelectChannelHandler = useCallback(
    async (sid) => {
      setSelectedChannel(sid);
      await getChannelBySid(sid);
    },
    [getChannelBySid],
  );

  if (!channelsPaginator) {
    const indicator = <Icon type="loading" spin />;
    return <Spin indicator={indicator} />;
  }

  return (
    <div className={classes.root}>
      <ChannelList
        channels={channels}
        getMoreChannels={getMoreChannels}
        paginator={channelsPaginator}
        onSelectChannel={onSelectChannelHandler}
        selectedChannel={selectedChannel}
      />
    </div>
  );
};

export default React.memo(SiderContent);
