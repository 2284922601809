/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-use-before-define */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _remove from 'lodash/remove';
import * as NegotiationServices from '../negotiation-services';

/**
 * Channel - Wrapper component for a channel. It needs to be place inside of the Chat component.
 * MessageList, MessageInput should be used as children of the Channel component.
 *
 */
const Channel = ({ channel, render }) => {
  const [messages, setMessages] = useState([]);
  const [paginator, setPaginator] = useState(null);
  const [loadingMessages, setLoadingMessages] = useState(true);
  const [listOfTypingMember, setListOfTypingMember] = useState([]);
  const [attributes, setAttributes] = useState(null);
  const [friendlyName, setFriendlyName] = useState('');
  const [sid, setSid] = useState('');
  useEffect(() => {
    const registerEvents = (instance) => {
      //instance.on('memberJoined', onMemberJoined)
      //instance.on('memberLeft', onMemberLeft)
      instance.on('messageAdded', onMessageAdded);
      instance.on('messageRemoved', onMessageRemoved);
      instance.on('messageUpdated', onMessageUpdated);
      instance.on('typingStarted', onTypingStarted);
      instance.on('typingEnded', onTypingEnded);
      instance.on('updated', onUpdated);
    };
    const initChannelInfo = (instance) => {
      setFriendlyName(instance.friendlyName);
      setAttributes(instance.attributes);
      setSid(channel.sid);
    };
    registerEvents(channel);
    initChannelInfo(channel);
  }, []);

  const getAttributes = async () => {
    try {
      const result = await channel.getAttributes();
      setAttributes(result);
      console.log('Channel getAttributes success:', result);
    } catch (ex) {
      console.log('Channel getAttributes error:', ex);
    }
  };

  const sendMessage = async ({ type, event, values }) => {
    let transferMessageObject;
    switch (type) {
      case 'MessageActions':
        transferMessageObject = NegotiationServices.handleActions(event, values);
        break;
      case 'MessageForm':
        transferMessageObject = NegotiationServices.handleForm(event, values);
        break;
      case 'MessageModal':
        transferMessageObject = NegotiationServices.handleForm(event, values);
        break;
      case 'MessageInput':
        transferMessageObject = NegotiationServices.handleInput(event, values);
        break;
      case 'MessageCarousel':
        transferMessageObject = NegotiationServices.handleCarousel(event, values);
        break;
      case 'MessageSystem':
        transferMessageObject = NegotiationServices.handleSystem(event, values);
        break;
      case 'MessageSelect':
        transferMessageObject = NegotiationServices.handleSelect(event, values);
        break;
      case 'MessageCustom':
        transferMessageObject = NegotiationServices.handleCustom(event, values);
        break;
      default:
        transferMessageObject = NegotiationServices.handleDefault(event, values);
        break;
    }
    console.log('Channel sendMessage:', transferMessageObject);
    const { message, messageAttributes } = transferMessageObject;
    try {
      const result = await channel.sendMessage(JSON.stringify(message), messageAttributes);
      if (Number.isNaN(result)) {
        console.log('Message failed to send');
      }
    } catch (ex) {
      console.log('Channel sendMessage error:', ex);
    }
  };

  const getMessages = async () => {
    try {
      setLoadingMessages(true);
      const pagination = await channel.getMessages();
      setPaginator(pagination);
      const { items } = pagination;
      setMessages(items);
      setLoadingMessages(false);
      console.log('Channel getMessages success:', items);
    } catch (ex) {
      console.log('Channel getMessages error:', ex);
      setMessages([]);
      setLoadingMessages(false);
    }
  };

  const getMoreMessages = async (direction) => {
    console.info('Channel getMoreMessages', direction);
    if (loadingMessages) {
      return;
    }
    if ((!paginator && !paginator.hasPrevPage) || (!paginator && !paginator.hasNextPage)) {
      return;
    }
    setLoadingMessages(true);
    if (direction === 'backwards') {
      try {
        const pagination = await paginator.prevPage();
        setMessages((prev) => {
          return [...pagination.items, ...prev];
        });
        setPaginator(pagination);
        console.info('Channel getMoreMessages success', paginator);
      } catch (ex) {
        console.log('getMoreMessages error:', ex);
      }
    } else {
      try {
        const pagination = await paginator.nextPage();
        setMessages((prev) => {
          return [...prev, ...pagination.items];
        });
        setPaginator(pagination);
        console.info('Channel getMoreMessages success', paginator);
      } catch (ex) {
        console.log('getMoreMessages error:', ex);
      }
    }
    setLoadingMessages(false);
  };

  const typing = async () => {
    try {
      await channel.typing();
      console.log('Channel typing');
    } catch (ex) {
      console.log('Channel typing error:', ex);
    }
  };

  const onMessageAdded = (message) => {
    console.info('Channel onMessageAdd:', message);
    setMessages((prev) => [...prev, message]);
  };

  const onMessageUpdated = (message) => {
    console.log('Channel onMessageUpdated:', message);
  };

  const onMessageRemoved = (message) => {
    console.log('Channel onMessageRemoved:', message);
    setChannels((prevMessages) => _remove(prevMessages, (current) => current.sid !== message.sid));
  };

  const onTypingStarted = (member) => {
    setListOfTypingMember((prev) => [...prev, member]);
    console.log('Channel onTypingStarted:', member);
  };

  const onTypingEnded = (member) => {
    setListOfTypingMember((prev) => _remove(prev, (current) => current.sid !== member.sid));
    console.log('Channel onTypingEnded:', member);
  };

  const onUpdated = ({ channel: ch, updateReasons }) => {
    setAttributes(ch.attributes);
    console.log('Channel onUpdated:', ch, updateReasons);
  };

  console.log('Channel render:', channel);
  return render({
    sid,
    getMessages,
    loadingMessages,
    messages,
    sendMessage,
    getMoreMessages,
    typing,
    listOfTypingMember,
    attributes,
    getAttributes,
    friendlyName,
    paginator,
  });
};

Channel.propTypes = {
  /**
   * The current channel this message is displayed in
   */
  channel: PropTypes.object.isRequired,
  /**
   * Using the render props to make the channel more flexible
   */
  render: PropTypes.func.isRequired,
};

export default Channel;
